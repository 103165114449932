import { Route, Routes } from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./views";
import './styles/main.scss';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import config from "./config";
function App() {
  window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: 'reverb',
    key:config.reverb.appKey,
    wsHost:config.reverb.host,
    wsPort:config.reverb.port ?? 80,
    wssPort:config.reverb.port ?? 443,
    forceTLS: (config.reverb.scheme ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    apiOriginAllow: {
      allowCors: true,
      allowOrigin: "*",
      allowMethods: "GET,POST",
      allowHeaders: "Origin, Content-Type, X-Auth-Token, X-Requested-With, Accept, Authorization, X-CSRF-TOKEN, X-Socket-Id"
    }
  });

  return (
    <Routes>
      <Route path="*" element={<Home />}></Route>
    </Routes>
  );
}

export default App;
