import { useTranslation } from 'react-i18next'
import tarjeta from '../../../../../../../../images/carrito/tarjeta.png'
import tarjetaDsk from '../../../../../../../../images/carrito/tarjetadesk.png'
import bizum from '../../../../../../../../images/carrito/bizum.png'
import bizumDsk from '../../../../../../../../images/carrito/Bizumdesk.png'
import { faExclamationTriangle, faLandmark, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import './metodosPago.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import useScreenWidth from '../../../../../../../../hooks/useScreenWidth'

const MetodosPagoList = ({checkoutInfo, paymentType, setPaymentType}) => {
  const { t } = useTranslation()
  const screenWidth = useScreenWidth()

  return <><div className='metodos-pago'>
        <div className={`metodos-pago__card ${paymentType === 1 ? 'selected' : ''}`}>
            <div onClick={() => setPaymentType(1)}>
                <img className='metodos-pago__card--visa-img' src={screenWidth > 1300 ? tarjetaDsk : tarjeta} alt='VISA'/>
                <FontAwesomeIcon className='icon-check' icon={faCircleCheck} />
            </div>
            <p>{t('carrito.pedido.tarjeta')}</p>
        </div>
        {checkoutInfo.cliente.pais_facturacion_id === 1 && <div className={`metodos-pago__card ${paymentType === 2 ? 'selected' : ''}`}>
            <div onClick={() => setPaymentType(2)}>
                <img className='metodos-pago__card--bizum-img' src={screenWidth > 1300 ? bizumDsk : bizum} alt='Bizum'/>
                <FontAwesomeIcon className='icon-check' icon={faCircleCheck} />
            </div>
            <p>{t('carrito.pedido.bizum')}</p>
        </div>}
        <div className={`metodos-pago__card ${paymentType === 3 ? 'selected' : ''}`}>
            <div onClick={() => setPaymentType(3)}>
                <span><FontAwesomeIcon icon={faLandmark} /></span>
                <FontAwesomeIcon className='icon-check' icon={faCircleCheck} />
            </div>
            <p>{t('carrito.pedido.transferencia')}</p>
        </div>
        {checkoutInfo.presupuesto.ecommerce && <div className={`metodos-pago__card ${paymentType === 5 ? 'selected' : ''}`}>
            <div onClick={() => setPaymentType(5)}>
                <span><FontAwesomeIcon icon={faMoneyBillTransfer} /></span>
                <FontAwesomeIcon className='icon-check' icon={faCircleCheck} />
            </div>
            <p>{t('carrito.pedido.efectivo')}</p>
        </div>}
    </div>
        { (paymentType === 2 && !checkoutInfo.presupuesto.ecommerce) && <div className="w-100 d-flex align-items-center alert alert-danger my-4" style={{opacity: (paymentType === 2) ? '1' : 0}}>
                <small className="w-100">
                    <div className='w-100 d-flex align-items-center justify-content-center'>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                        <span className='mx-2'><b>{t('carrito.pedido.bizum-alert.title')}</b></span>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                    </div>

                    <span className="d-block mt-2 text-center">
                        <b>{t('carrito.pedido.bizum-alert.text.1')}</b> {t('carrito.pedido.bizum-alert.text.2')} <br />
                        {t('carrito.pedido.bizum-alert.text.3')}
                    </span>
                </small>
            </div> }
        {
            paymentType === 5 &&
                <div className="w-100 d-flex flex-column align-items-start alert alert-pago-efectivo my-4">
                    <h4 className="fw-bold text-uppercase">{t('carrito.pago.transferencia.informacion')}</h4>
                    <p className='pb-3'>{t('carrito.pago.efectivo.step1.p1')}</p>
                    <p>{t('carrito.pago.efectivo.step1.prev-step')}</p>
                </div>
        }
    </>
}

export default MetodosPagoList
