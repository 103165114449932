import i18n from 'i18next'
import './languageBtn.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

const LanguageBtn = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }
  return (
    <div className="change-language">
      <FontAwesomeIcon className="change-language__icon" icon={faGlobe} />
      <select className="change-language__select" defaultValue={i18n.language} name="language" onChange={(e) => changeLanguage(e.target.value)}>
          <option value='es-ES'>ES</option>
          <option value='pt-PT'>PT</option>
      </select>
    </div>
  )
}

export default LanguageBtn
