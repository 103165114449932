import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { toast } from "react-toastify";
import Loading from "../Checkout/components/shared/components/Loading";
import { checkPurchaseDone } from "../../../shared/services/checkout";
import { useTranslation } from 'react-i18next';

const CheckoutDone = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = queryString.parse(location.search);
    const { t } = useTranslation()

    useEffect(() => {
       const checkPurchase = async() => {
            const ventaResponse = await checkPurchaseDone({
                'Ds_SignatureVersion': params.Ds_SignatureVersion,
                'Ds_MerchantParameters': params.Ds_MerchantParameters,
                'Ds_Signature': params.Ds_Signature
            });

            if(ventaResponse && ventaResponse.success && ventaResponse.message !== 'ERROR!') {
                navigate('/checkout/' + ventaResponse.data, {replace: true});
            } else {
                toast.error(t('errors.checkout-done'));
                navigate('/checkout/' + ventaResponse.data, {replace: true});
            }
       } 
        
       checkPurchase();
    }, []);

    return (
        <Loading />
    )
}

export default CheckoutDone