import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function ComprovanteTransfEnviado({
    checkoutInfo
}) {
    const { t } = useTranslation()
    useEffect(() => {
        
    }, [checkoutInfo]);

    return (
        <div className="sended-receipt w-100 d-flex align-items-center justify-content-center mt-4">
            <div className="col-12 col-md-10 col-lg-8">
                <div className="w-100 custom-card p-3 d-block">
                    <div className="w-100 text-center">
                        <span className="circle-done-big">
                            <FontAwesomeIcon icon={faCheck} size="2x" color="#215732" />  
                        </span>
                        
                        <h3 className="mt-3">{t('carrito.pago.sended-receipt.title')}</h3>
                    </div>

                    
                    <div className="w-100 d-flex flex-column mt-4 checkout-info">
                        <small>{t('carrito.pago.sended-receipt.text.1')}  <b>{checkoutInfo.cliente?.email}</b> {t('carrito.pago.sended-receipt.text.2')}  <br />
                        {t('carrito.pago.sended-receipt.text.3')}</small>
                        
                        <div className="w-100 mt-3 d-flex flex-column flex-md-row justify-content-between">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faMobileAlt} size="1x" className="action" color="#215732" />  
                                <p className="ms-2"><a href={"tel:+34" + checkoutInfo.telefono_recambista}>{checkoutInfo.telefono_recambista}</a></p>
                            </div>

                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" color="#215732" /> 
                                <p className="ms-2"><a href="mailto:info@recomotor.com">info@recomotor.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}