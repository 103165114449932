import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelope, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function VentaSuccess({
    checkoutId,
    checkoutInfo
}) {
    const { t } = useTranslation()

    useEffect(() => {
    }, [checkoutInfo]);

    return (
        <div className="w-100 d-flex align-items-center justify-content-center my-5 venta-success">
            <div className="col-12 col-md-8 col-lg-6">
                <div className="w-100 custom-card p-3 d-block">
                    <div className="w-100 text-center">
                        <span className="circle-done-big">
                            <FontAwesomeIcon icon={faCheck} size="2x" color="#215732" />  
                        </span>
                        
                        <h3 className="mt-3">{t('venta-success.1')}</h3>
                    </div>

                    <p className="text-center mt-3">
                        {t('venta-success.2')} <b>{checkoutInfo?.is_venta?.numero}</b> {t('venta-success.3')}
                    </p>

                    <p className="text-center mt-2">
                        {t('venta-success.4')} <Link to={"/seguimiento/" + checkoutId} title="Aviso Legal" target={"_blank"}>
                            <span className="link fw-bold">{t('venta-success.5')}</span>
                        </Link>
                    </p>
                    
                    <div className="w-100 d-flex flex-column mt-4 checkout-info">
                        <small>{t('venta-success.6')} <b>{checkoutInfo.cliente?.email}</b>. <br />
                        {t('venta-success.7')}</small>
                        
                        <div className="w-100 mt-3 d-flex flex-column flex-md-row justify-content-between">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faMobileAlt} size="1x" className="action" color="#215732" />  
                                <p className="ms-2"><a href={"tel:+34" + checkoutInfo.telefono_recambista}>{checkoutInfo.telefono_recambista}</a></p>
                            </div>

                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" color="#215732" /> 
                                <p className="ms-2"><a href="mailto:info@recomotor.com">info@recomotor.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}