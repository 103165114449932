import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import './checkout.scss';
import { useDispatch, useSelector } from "react-redux";
import { fetchCheckout } from "../../../redux/checkout";
import Loading from "./components/shared/components/Loading";
import Navbar from "../../shared/components/Navbar";
import Status from "./components/Status";

const Checkout = () => {
    const dispatch = useDispatch();
    const { checkoutId } = useParams();
    const checkoutStatus = useSelector(state => {
        return state.checkout.status
    });
    const webSocketChannel = `checkout.${checkoutId}`;

    useEffect(() => {
        dispatch(fetchCheckout(checkoutId));
        connectWebSocket();

        return () => {
            window.Echo.leave(webSocketChannel);
        }
    }, []);


    const connectWebSocket = () => {
        window.Echo.channel(webSocketChannel)
            .listen('.UpdateCheckout', async (e) => {
                dispatch(fetchCheckout(checkoutId));
            });
    }

    return (
        <div className="w-100 h-100" style={{ overflowY: 'auto'}}>
            <Navbar />
            
            { (checkoutStatus.loading === 'succeed' || checkoutStatus.loading === 'rejected') ?
                <div className="container">
                    <Status 
                        checkoutId={checkoutId}
                    />
                </div>
                : 
                <div className="container h-100">
                    <Loading />
                </div>
            }
            

            <ToastContainer
                containerId="checkout"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
            />
        </div>
    )
}

export default Checkout