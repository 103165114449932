// import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../../../images/logo.png';
import { useTranslation } from 'react-i18next';
import LanguageBtn from './LanguageBtn';
import config from "../../../../config";
const Navbar = () => {
    let pathname = window.location.pathname === '/' ? 'home' : window.location.pathname.replace('/', '');
    const { t } = useTranslation()
    // const [isViewInfo, setIsViewInfo] = useState(true);

    // useEffect(() => {

    // }, []);

    // const handleSetInfo = (value) => { 
    //     const summary = document.getElementById('checkout-summary');
    //     setIsViewInfo(value); 

    //     if(value) summary.classList.add('show');
    //     else summary.classList.remove('show');
    // }

    return (
        <div className="container">
            <nav className="navbar navbar-expand-md">
                <div className="container-fluid p-0">
                    <span className="navbar-brand">
                        <img src={logo} className="logo d-block nav-logo-img" alt="Recomotor" title={config.app.version} />
                    </span>
                    
                    <button 
                        className="navbar-toggler" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#toggler" 
                        aria-controls="toggler" 
                        aria-expanded="false" 
                        aria-label="Menú desplegable">
                            <span className="navbar-toggler-icon text-success"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="toggler">
                        <ul className="navbar-nav flex-grow-1 d-flex align-items-center justify-content-end">
                            <li className={"nav-item " + (pathname.toLowerCase() === 'aviso-legal' ? "active" : "")}>
                                <Link to={"/aviso-legal"} title="Aviso Legal" target={"_blank"}>
                                    <span className="link">{t('nav.aviso')}</span>
                                </Link> 
                            </li>
                            <li className={"nav-item " + (pathname.toLowerCase() === 'condiciones-generales' ? "active" : "")}>
                                <Link to={"/condiciones"} title="Condiciones Generales"  target={"_blank"} className="ms-3">
                                    <span className="link">{t('nav.condiciones')}</span>
                                </Link> 
                            </li>
                            <li>
                                <LanguageBtn />
                            </li>
                            {/* { (pathname.toLowerCase()?.includes('checkout')) ?
                                <li className="nav-item me-0">
                                    <button type="button" className="btn btn-default" onClick={() => handleSetInfo(!isViewInfo)}>
                                        {isViewInfo ? 'Ocultar' : 'Mostrar'} resumen
                                    </button> 
                                </li>
                                : ''
                            } */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar