import { FormControl, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const DeliverySchedule = ({ direccion, setValue }) => {
  const { t } = useTranslation()
  const hourRange = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  const [horasInicioDia, setHorasInicioDia] = useState(hourRange);
  const [horasInicioTarde, setHorasInicioTarde] = useState(hourRange);
  const [horasFinDia, setHorasFinDia] = useState(hourRange);
  const [horasFinTarde, setHorasFinTarde] = useState(hourRange);
  const [scheduleState, setScheduleState] = useState({
    start_morning: '',
    end_morning: '',
    start_afternoon: '',
    end_afternoon: ''
  })

  useEffect(() => {
    setScheduleState({
        start_morning: direccion?.horario_entrega_dia ? parseInt(direccion?.horario_entrega_dia.split('-')[0]) : "",
        end_morning: direccion?.horario_entrega_dia ? parseInt(direccion?.horario_entrega_dia.split('-')[1]) : "",
        start_afternoon: direccion?.horario_entrega_tarde ? parseInt(direccion?.horario_entrega_tarde.split('-')[0]) : "",
        end_afternoon: direccion?.horario_entrega_tarde ? parseInt(direccion?.horario_entrega_tarde.split('-')[1]) : ""
    })
  }, [direccion])
  
  
  return (
    <div className='delivery-schedule'>
        <h4>{t('carrito.direccion.horario')}</h4>
        <div className='delivery-schedule__each-container'>   

            <div className='delivery-schedule__each-container--inputs'>
            
                <div className='form-dir-envio__div'>
                    <label className='form-dir-envio__label'>{t('carrito.envio.form.schedule.morning.start')}
                    <FormControl className='select-horario'>
                        <Select
                            value={scheduleState.start_morning}
                            onChange={(e) => {
                                let value = e.target.value
                                if(e.target.value !== "") {
                                    value = parseInt(e.target.value);
                                    const index = hourRange.findIndex(f => f === value);
                                    const newEndRange = hourRange.slice(index+1, hourRange.length);

                                    setHorasFinDia(newEndRange);
                                }
                                setValue('horario_inicio_dia', value);
                                setScheduleState({...scheduleState, start_morning: value})
                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                        <MenuItem value={''}>{t('carrito.envio.form.schedule.select')}</MenuItem>
                            {horasInicioDia?.map(op => <MenuItem key={op} value={op}>{op + ':00h'}</MenuItem>)}
                        </Select>
                    </FormControl>
                    </label>
                </div>

                <div className='form-dir-envio__div'>
                    <label className='form-dir-envio__label'>{t('carrito.envio.form.schedule.morning.end')}
                        <FormControl className='select-horario'>
                            <Select
                                value={scheduleState.end_morning}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if(e.target.value !== "") {
                                        value = parseInt(e.target.value);
                                        const index = hourRange.findIndex(f => f === value);
                                        const newStartRange = hourRange.slice(index+1, hourRange.length);
                                        const newEndRange = hourRange.slice(0, index);
    
                                        setHorasInicioDia(newEndRange);
                                        setHorasInicioTarde(newStartRange)
                                    }
                                    setValue('horario_fin_dia', value);
                                    setScheduleState({...scheduleState, end_morning: value})
                                }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                            <MenuItem value={''}>{t('carrito.envio.form.schedule.select')}</MenuItem>
                                {horasFinDia?.map(op => <MenuItem key={op} value={op}>{op + ':00h'}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </label>
                </div>
            </div>
        </div>

        <div className='delivery-schedule__each-container'>
            <div className='delivery-schedule__each-container--inputs'>
                <div className='form-dir-envio__div'>
                    <label className='form-dir-envio__label'>{t('carrito.envio.form.schedule.afternoon.start')}
                        <FormControl className='select-horario'>
                            <Select
                                value={scheduleState.start_afternoon}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if(e.target.value !== "") {
                                        value = parseInt(e.target.value);
                                        const index = hourRange.findIndex(f => f === value);
                                        const newEndRange = hourRange.slice(index+1, hourRange.length);
                                        
                                        setHorasFinTarde(newEndRange);
                                    }
                                    setValue('horario_inicio_tarde', value);
                                    setScheduleState({...scheduleState, start_afternoon: value})
                                }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                            <MenuItem value={''}>{t('carrito.envio.form.schedule.select')}</MenuItem>
                                {horasInicioTarde?.map(op => <MenuItem key={op} value={op}>{op + ':00h'}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </label>
                </div>

                <div className='form-dir-envio__div'>
                    <label className='form-dir-envio__label'>{t('carrito.envio.form.schedule.afternoon.end')}
                        <FormControl className='select-horario'>
                            <Select
                                value={scheduleState.end_afternoon}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if(e.target.value !== "") {
                                        value = parseInt(e.target.value);
                                        const index = hourRange.findIndex(f => f === value);
                                        const newStartRange = hourRange.slice(0, index);
    
                                        setHorasInicioTarde(newStartRange);
                                    }
                                    setValue('horario_fin_tarde', value);
                                    setScheduleState({...scheduleState, end_afternoon: value})
                                }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                            <MenuItem value={''}>{t('carrito.envio.form.schedule.select')}</MenuItem>
                                {horasFinTarde?.map(op => <MenuItem key={op} value={op}>{op + ':00h'}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </label>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DeliverySchedule
