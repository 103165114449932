import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { isOnVacation } from "../../../../../../shared/helpers/functionalities";

export default function PresupuestoIssue({
    checkoutInfo
}) {
    const { t } = useTranslation()
    const [proveedoresEnVacaciones, setProveedoresEnVacaciones] = useState(null);

    useEffect(() => {
        if(!checkoutInfo.presupuesto.validado) {
            const piezas = checkoutInfo.presupuesto.piezas;
            const onVacation = piezas?.filter((pieza) => isOnVacation(pieza.proveedor));
            setProveedoresEnVacaciones(onVacation);
        }
    }, [checkoutInfo]);

    return (
        <div className="w-100 d-flex align-items-center justify-content-center presupuesto-issue">
            <div className="d-flex flex-column align-items-center justify-content-center custom-card">
                <h3 className="d-flex flex-column text-center">
                    <span className="fw-bold">
                        { checkoutInfo.presupuesto.expirado === 1 ?
                            t('presupuesto-issue.expirado')
                            : !checkoutInfo.presupuesto.validado ? 
                               t('presupuesto-issue.sin-validar')
                            : (checkoutInfo.presupuesto.piezas.find(p => p.integracion_ventas?.length > 0)) ?
                               t('presupuesto-issue.vendida')
                            : t('presupuesto-issue.incidencia')
                        }
                    </span>
                </h3>

                { (proveedoresEnVacaciones && proveedoresEnVacaciones.length > 0) &&
                    <div className="w-100 mt-4">
                        <h5 className="text-danger fw-bold">{proveedoresEnVacaciones.length === 1 ? t('presupuesto-issue.no-disponible') : t('presupuesto-issue.no-disponibles')}</h5>
                        <ul className="mt-2">
                            { proveedoresEnVacaciones?.map((pieza) => {
                                return (
                                    <li>
                                        <p>{pieza.nombre}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                }

                <small className="mt-5 text-center fw-bold">{t('presupuesto-issue.contacta')}</small>
                    
                <div className="w-100 mt-3 d-flex flex-column flex-md-row justify-content-center">
                    <div className="d-flex align-items-center me-3">
                        <FontAwesomeIcon icon={faMobileAlt} size="1x" className="action" color="#215732" />  
                        <small className="ms-1"><a href={"tel:+34" + checkoutInfo.telefono_recambista}>{checkoutInfo.telefono_recambista}</a></small>
                    </div>

                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" color="#215732" /> 
                        <small className="ms-1"><a href="mailto:info@recomotor.com">info@recomotor.com</a></small>
                    </div>
                </div>
            </div>
        </div>
    )
}