module.exports = {
    app: {
        version: `${process.env.REACT_APP_VERSION || 'dev'}` 
    },
    recomotor: {
        crmApiUrl: `${process.env.REACT_APP_CRM_API_URL}/api/`,
        stockApiUrl: `${process.env.REACT_APP_STOCK_API_URL}/recomotor-stock-rest/`,
        ftpApiUrl: `${process.env.REACT_APP_STOCK_API_URL}/recomotor-images-rest/`,
    },
    reverb: {
        appId: process.env.REVERB_APP_ID,
        appKey: process.env.REACT_APP_REVERB_APP_KEY,
        host: process.env.REACT_APP_REVERB_HOST,
        port: process.env.REACT_APP_REVERB_PORT || 8080,
        scheme: process.env.REACT_APP_REVERB_SCHEME || 'http'
    },
    googlePlaces: {
        apiKey: process.env.REACT_APP_PLACES_API_KEY
    },
    i18n: {
        debug: process.env.REACT_APP_I18_DEBUG || false,
        fallbackLng: 'es'
    }
}
