import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { fetchRecalculateShippingPrice } from '../../../../../redux/checkout';
import Carrito from "./components/Carrito";
import PresupuestoIssue from "./components/PresupuestoIssue";
import VentaSuccess from "./components/VentaSuccess";

const Status = ({
    checkoutId,
}) => {
    const dispatch = useDispatch();
    const checkoutInfo = useSelector(state => {
        return state.checkout.entity
    });
    useEffect(() => {
        // recalcular precio envio si el presupuesto tiene dirección de envio asignada y se han añadido nuevas piezas al carrito
        if (checkoutInfo.presupuesto.ecommerce && checkoutInfo.presupuesto.direccion_envio_id) {
            const partsWithShippingPrice = checkoutInfo.presupuesto.piezas.filter(part => 
                checkoutInfo.presupuesto.servicios_envio.find(service => 
                    service.pieza_id === part.id && service.precio_transporte > 0)
            )
            if(partsWithShippingPrice.length !== checkoutInfo.presupuesto.piezas.length) {
                dispatch(fetchRecalculateShippingPrice(checkoutInfo.presupuesto.id));
            }
        }
    }, [])
    

    return (
        <div className="w-100 container">
            { ((checkoutInfo.presupuesto.validado && !checkoutInfo.presupuesto.expirado) &&
                (!checkoutInfo.presupuesto.piezas.find(p => p.integracion_ventas?.length > 0 && p.integracion_ventas?.find(v  => v.id !== checkoutInfo?.is_venta?.id)))
            ) ?
                <>
                    { !checkoutInfo.is_venta &&
                            <Carrito />
                    }

                    { checkoutInfo.is_venta &&
                        <VentaSuccess 
                            checkoutId={checkoutId}
                            checkoutInfo={checkoutInfo}
                        />
                    }
                </>
                : 
                <PresupuestoIssue
                    checkoutInfo={checkoutInfo}
                />
            }
        </div>
    )
}

export default Status