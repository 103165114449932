import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AvisoLegal from "./Checkout/AvisoLegal";
import Checkout from "./Checkout/Checkout";
import CheckoutDone from "./Checkout/CheckoutDone";
import CheckoutError from "./Checkout/CheckoutError";
import CondicionesGenerales from "./Checkout/CondicionesGenerales";
import TransferValidate from "./Checkout/TranferValidate";
import Seguimiento from "./Seguimiento";
import NotFound from "./NotFound";
import FloatingWhatsApp from './shared/components/FloatingWhatsApp';
import useScreenWidth from "../hooks/useScreenWidth";

const Home = () => {
    useEffect(() => {}, []);
    const screenWidth = useScreenWidth()

      return (
        <div className="w-100 h-100">
            <Routes>
                <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                <Route path="/" exact element={<NotFound />}></Route>
                <Route path="/checkout/:checkoutId" element={<Checkout />}></Route>
                <Route path="/seguimiento/:checkoutId" element={<Seguimiento />}></Route>
                <Route path="/aviso-legal" element={<AvisoLegal />}></Route>
                <Route path="/condiciones" element={<CondicionesGenerales />}></Route>
                <Route path="/checkout-success" element={<CheckoutDone />}></Route>
                <Route path="/checkout-error" element={<CheckoutError />}></Route>
                <Route path="/checkout/validate/:checkoutId" element={<TransferValidate />}></Route>
            </Routes>

            <ToastContainer
                containerId="dashboard"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />

            { (screenWidth >= 768) ?
                <FloatingWhatsApp
                allowEsc
                allowClickAway
                notification
                // notificationSound
                />
                : null
            }
        </div> 
    );
}

export default Home