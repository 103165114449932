import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from 'query-string';
import Loading from "../Checkout/components/shared/components/Loading";
import { transferCheckoutDone } from "../../../shared/services/checkout";

const TransferValidate = () => {
    const { checkoutId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const params = queryString.parse(location.search);

    useEffect(() => {
        const createVenta = async() => {
            const ventaResponse = await transferCheckoutDone(checkoutId, {
                'aprobado': params.validate,
            });

            if(ventaResponse && ventaResponse.success) {
                navigate('/checkout/' + ventaResponse.data, {replace: true});
            }
       } 
        
       if(params.validate === "true") {
            createVenta();
       }
    }, []);

    return (
        <Loading />
    )
}

export default TransferValidate