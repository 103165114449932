import PropTypes from 'prop-types'

import './direccionCard.scss'
import { useTranslation } from 'react-i18next'
import Spinner from '../shared/components/Spinner'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

const DireccionCard = ({ direccion, isSelected, setDirEnvio, setIsOpenForm, isDirEnvio = true, isOutOfRange, isLoadingSelectDirEnvio, handleEditSchedule }) => {
  const { t } = useTranslation()
  const checkoutInfo = useSelector(state => {
    return state.checkout.entity
  });
  return <div className={`direccion-card ${isSelected ? 'selected' : ''} ${isOutOfRange && isDirEnvio ? 'out-of-range' : ''}`}>
        {isDirEnvio &&
            <input
                onChange={() => {
                  if (!isOutOfRange) {
                    !isLoadingSelectDirEnvio && setDirEnvio(direccion)
                    setIsOpenForm(false)
                  }
                }}
                type='radio'
                name='direccion'
                checked={isSelected}/>}
       <div className={`direccion-card__text-container ${isDirEnvio ? '' : 'direccion-card__no-cursor-pointer'}`} onClick={() => { 
          if(isDirEnvio && !isOutOfRange) {
            setDirEnvio(direccion)
            setIsOpenForm(false)
          }
        }}>
            <h4>{direccion.nombre}</h4>
            {!isDirEnvio && <h5>{direccion?.cif}</h5>}
            {!isDirEnvio && <h5>{direccion?.email}</h5>}
            {isDirEnvio
              ? <p>{direccion.direccion}, {direccion.codigo_postal} {direccion.poblacion}, {direccion.area}, {direccion.pais.nombre}</p>
              : <p>{direccion.direccion_facturacion}, {direccion?.codigo_postal_facturacion} {direccion?.poblacion_facturacion}, {direccion?.area_facturacion}, {direccion.pais_facturacion?.nombre}</p>}
            {isDirEnvio && <><p>{t('carrito.direccion.tlf')} {direccion.telefono}</p></>}
            {(!isDirEnvio && direccion?.pais_facturacion_id !== 1) && 
              <p className='direccion-card__cif-ue'>
                <input type='checkbox' disabled checked={checkoutInfo.cliente.cif_ue === 1 || checkoutInfo.cliente.cif_ue === true} />
                <span>{t('carrito.envio.form.cif_ue')}</span>
              </p>}
            {
              isDirEnvio && <p>
                <span>{t('carrito.direccion.horario')}: </span>
                {(!direccion.horario_entrega_dia && !direccion.horario_entrega_tarde) 
                  ? '-' 
                  : `${direccion.horario_entrega_dia ? direccion.horario_entrega_dia : ''}${(direccion.horario_entrega_dia && direccion.horario_entrega_tarde) ? ' / ' : ''}${direccion.horario_entrega_tarde ? direccion.horario_entrega_tarde : ''}`}
                </p>
            }
            {isDirEnvio && isOutOfRange &&
              <p className='direccion-card__text-out-of-range'>({t('errors.fuera-rango')})</p>
            }
        </div>
        {isDirEnvio && !isOutOfRange && <div className={`direccion-card__editable ${isLoadingSelectDirEnvio === direccion.id ? 'direccion-card__noneditable' : ''}`}>
                <span onClick={(e) => handleEditSchedule(e, direccion)}><FontAwesomeIcon icon={faPenToSquare} /></span>
            </div>}
        {isLoadingSelectDirEnvio === direccion.id && !isOutOfRange && <Spinner className='spinner-dir-envio'/>}
    </div>
}

DireccionCard.propTypes = {
  direccion: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setDirEnvio: PropTypes.func,
  setIsOpenForm: PropTypes.func,
  isDirEnvio: PropTypes.bool,
  isOutOfRange: PropTypes.bool,
  handleEditSchedule: PropTypes.func

}

export default DireccionCard
