import axios from 'axios';
import config from '../../config';
const API = axios.create({
  baseURL: config.recomotor.crmApiUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

API.interceptors.request.use(
  (config) => { return config; },
  (error) => Promise.reject(error),
);

API.interceptors.response.use((response) => {
  return response
}, async function (error) {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
});

export default API;