import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import PropTypes from 'prop-types'

import './inputPaises.scss'

const InputPaises = ({ value = '', onChange, paises}) => {
  return (
    <FormControl id='input-paises'>
        <Select
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {paises.map(pais => <MenuItem key={pais.id} value={pais.id}>{pais.nombre}</MenuItem>)}
        </Select>
      </FormControl>
  )
}

InputPaises.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  paises: PropTypes.array.isRequired,
  disabled: PropTypes.any
}

export default InputPaises
