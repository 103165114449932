import TransferenciaForm from './components/TransferenciaForm';
import RedsysForm from './components/RedsysForm';

export default function Pago({
    checkoutInfo,
    paymentType,
    paymentForm,
    onUploadComprobante,
    setPaymentStep
}) {

    return (
        <div>
            {(paymentType === 1 || paymentType === 2) &&
                <div className='w-100'>
                  { paymentForm &&
                    <RedsysForm form={paymentForm} />
                  }
                </div>
            }
            { (paymentType === 3 || paymentType === 5) &&
                <div>
                  <TransferenciaForm 
                    clienteId={checkoutInfo.cliente.id}
                    numero_presupuesto={checkoutInfo.presupuesto?.numero}
                    total={checkoutInfo.presupuesto?.total_iva}
                    onSubmitFile={onUploadComprobante}
                    setPaymentStep={setPaymentStep}
                    paymentType={paymentType}
                  />
                </div>
            }
        </div>
    );
}