import { useEffect, useState } from 'react';
import imgDefault from '../../../../../../../../../images/carrito/placeholder.jpg';

const Image = ({ json, className = '' }) => {
  const [foto, setFoto] = useState('')
  const [isImgDefault, setIsImgDefault] = useState(false)

  useEffect(() => {
    if (json) {
      const jsonDecoded = JSON.parse(json)
      return setFoto(imgDefault);
      /*
      if(jsonDecoded.imgs?.length > 0) {
        const img = (jsonDecoded.imgs?.split(',')[0] || '')
        setFoto(`https://d2g2xclmj7afe.cloudfront.net/${img}`)
      } else {
        
      }*/
    }
  }, [])
  
  return (
    <img
      src={`https://d2g2xclmj7afe.cloudfront.net/${foto}`}
      onError={(e) => {
        setIsImgDefault(true)
        e.target.src = imgDefault
      }}
      className={`${className} ${isImgDefault ? 'img-default' : ''}`}
      alt='img'
    />
  )
}

export default Image

